import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import { Grid, Row, Col } from 'react-flexbox-grid'

import findImage from '../../utils/findImage'
import Layout from '../../components/Layout'
import Preview from '../../components/Preview'
import Phone from '../../components/Preview/Phone'
import Tablet from '../../components/Preview/Tablet'
import BodyColor from '../../components/BodyColor'
import styles from './sol-noctis.module.scss'
import WorkTitleBlock from '../../components/WorkTitleBlock';

export const frontmatter = {
  title: 'Sol Noctis Promo Site',
  path: '/works/web/sol-noctis-promo/',
  type: 'Web design and development',
  client: 'Private',
  date: '2014-05-22',
  image: 'sol-noctis-demo',
  favorite: true,
}

const SolNoctis = ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativePath: { regex: "^/sol-noctis/i/" } }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  return (
    <Layout location={location} className={styles.outerWrap}>
      <Helmet title={frontmatter.title} />
      <BodyColor isDark className={styles.page} />

      <div className={styles.innerWrap}>
        <h1 className="text-center">{frontmatter.title}</h1>
        <p className="aligncenter text-center">
          Design a website that will spike the interest in new coin.
        </p>

        <div className={styles.hero}>
          <Grid fluid>
            <Row>
              <Col xs={9}>
                <Tablet
                  fluid={findImage(images, 'sol-noctis-home')}
                  alt="Home Page in tablet mode"
                  className={styles.heroTablet}
                  shadow
                  isDark
                />
              </Col>
              <Col xs={3}>
                <Phone
                  fluid={findImage(images, 'sol-noctis-home-mobile')}
                  alt="Sol Noctis home page on phone"
                  className={styles.heroPhone}
                  isDark
                />
              </Col>
            </Row>
          </Grid>
        </div>

        <p className="aligncenter text-center">
          Sol Noctis is a&nbsp;silver coin worth 0.01 bitcoin. Issued in
          a&nbsp;limited edition of 100,000&nbsp;copies.
        </p>

        <p className="aligncenter text-center">
          While the coin was in print, we made a promotional site for it, where
          you can learn about the coin and leave your email address to not to
          miss the release of the coin for sale.
        </p>

        <figure className="text-center">
          <Preview
            fluid={findImage(images, 'sol-noctis-bitcoin')}
            url="What is Bitcoin?"
            alt="What is Bitcoin page"
            isDark
          />
        </figure>
      </div>

      <WorkTitleBlock {...frontmatter} />

    </Layout>
  )
}

export default SolNoctis
